import { useEffect, useState ,useContext} from "react";
// components
import {TextField, Typography} from "@material-ui/core";
import {Button} from "@mui/material";

//context
import ContextSocket from "../context/context-socetio";

function ReachTest(data) {
    //socket context

    //patient height
    const [patientHeightInput, setPatientHeightInput] = useState("")

    //data storage in states
    const [currentClick, setCurrentClick] = useState(undefined);
    const [lastClick, setLastClick] = useState(undefined);

    //reach test value
    const [reachTestEndedValue, setReachTestEndedValue] = useState(undefined);

    //patient facing right or left or front
    const [facingLeft, setFacingLeft] = useState(undefined)
    const [facingRight, setFacingRight] = useState(undefined)

    //btn disabled
    const [btnClickable, setBtnClickable] = useState(true);

    //phases
    const [visibilityPhase, setVisibilityPhase] = useState(false);
    const [pretestPhase, setPretestPhase] = useState(false);

    //end test
    const [anklesPosture,setAnklesPosture] = useState(false)

    //input validation
    const [error,setError]= useState(false)

    const Socket = useContext(ContextSocket);
    Socket.emit("protocol", data.poseLandmarksState);

    // aspect ratio
    const aspectRatio_16_9 = 16/9;

    useEffect(() => {
        const listener = (res) => {
            //visibility test phase
            if(res?.is_patient_facing_right) {
                setFacingRight(true)
                if(res?.is_patient_visible_right) {
                    setVisibilityPhase(true)
                } else {
                    setVisibilityPhase(false)
                }
            } else {
                setFacingRight(false)
            }
            //visibility test phase
            if(res?.is_patient_facing_left) {
                setFacingLeft(true)
                if(res?.is_patient_visible_left) {
                    setVisibilityPhase(true)
                } else {
                    setVisibilityPhase(false)
                }
            } else {
                setFacingLeft(false)
            }

            //check if pretest elements done correctly
            if(res?.is_shoulders_in_right_posture === true
                && res?.is_hips_in_right_posture === true
                && res?.is_ankles_in_right_posture === true
                )
            {
                setPretestPhase(true)
            }else{
                setPretestPhase(false)
            }

            //end test
            if (res.is_ankles_in_right_posture){
                setAnklesPosture(true)
            }else{
                setAnklesPosture(false)
            }

        };
            Socket.on("protocol", listener);
        return () => {
            Socket.off("protocol", listener);
        };
    }, []);

    const measurementMDtoCMHelperFunction = (firstIndex,secondIndex,testResult) =>{
        let indexesRatio = firstIndex/secondIndex;
        return indexesRatio * testResult;
    }

    const handleClick = async (e) => {
        setError(false)
        if (patientHeightInput === ''){
            setError(true)
        }else{
            //emiting reach test values to server
            await Socket.emit("reach_test", {currentClick:data.poseLandmarksState,lastClick:currentClick})
            //first click check
            if(currentClick !== undefined){
                setLastClick(currentClick)
            }
            setCurrentClick(data.poseLandmarksState);
            e.preventDefault()
        }
    }

    useEffect(() => {
        //start function
        const data = (res) => {
            if (facingLeft){
                let val = measurementMDtoCMHelperFunction(patientHeightInput -7 , parseFloat(res?.height_in_px), parseFloat(res?.left_wrist_index))
                setReachTestEndedValue(val * aspectRatio_16_9)
            }
            if(facingRight){
                let val = measurementMDtoCMHelperFunction(patientHeightInput -7 , parseFloat(res?.height_in_px), parseFloat(res?.right_wrist_index))
                setReachTestEndedValue(val * aspectRatio_16_9)
            }
        };
            //getting data from the server
            Socket.on("reach_test_res", data);
        return () => {
            Socket.off("reach_test_res", data);
        };
    }, [patientHeightInput, facingLeft, facingRight]);

    useEffect(()=>{
        if (pretestPhase && visibilityPhase) {
            setBtnClickable(false);
        } else {
            setBtnClickable(true);
        }

    },[pretestPhase, visibilityPhase, btnClickable])

    return (
        <>
            <div>
                <TextField
                    label="patient height"
                    value={patientHeightInput}
                    onChange={(e)=> {
                        setPatientHeightInput(e.target.value)
                    }}
                    variant="outlined"
                    required
                    error={error}
                />
                <Typography variant={'h6'}>{`visibility phase: ${visibilityPhase}`}</Typography>
                <Typography variant={'h6'}>{`pretest phase: ${pretestPhase}`}</Typography>
                <Button
                    disabled={btnClickable}
                    variant="contained"
                    color="primary"
                    onClick={handleClick}>
                    התחל מבחן
                </Button>
                {reachTestEndedValue ? <Typography variant={'h6'}>{`reach test value: ${reachTestEndedValue}`}</Typography>:null}

                {facingRight ?  <Typography variant={'h3'} >{`YOU FACING RIGHT SIDE`}</Typography> :
                    <Typography variant={'h3'} >{`YOU FACING LEFT SIDE`}</Typography>
                }

                {!anklesPosture &&
                    (
                        <div>
                            <Typography variant={'h3'}>test failed</Typography>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={()=> {
                                    setReachTestEndedValue(0)
                                    setCurrentClick(0)
                                    setLastClick(0)} }>
                                אפס תוצאה
                            </Button>
                        </div>
                    )}
            </div>
        </>
    );
}
export default ReachTest;
