import {useRef, useState} from "react";
// components
import {makeStyles, Typography} from "@material-ui/core";
import {Switch, CircularProgress } from "@mui/material";
import ServerResultsCanvas from "./ServerResultsCanvas";

// ready to use API (https://google.github.io/mediapipe/getting_started/javascript.html#ready-to-use-javascript-solutions)
import {Pose} from "@mediapipe/pose";
import RangeOfMotion from "../RangeOfMotion";
import ReachTest from "../ReachTest";
import Camstream from "./Camstream";

const useStyles = makeStyles((theme) => ({
    main: {
        opacity: (loader) => (loader ? 0 : 1),
        transition: "0.5s",
    },
    loader: {
        margin: "64px auto",
    },
    loaderContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
    },
    switchBtn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

function CamView() {

    //video needs
    const canvasRef = useRef(null);
    const webCamRef = useRef(null);
    const [img, setImg] = useState(undefined);
    const [poseLandmarksState, setPoseLandmarksState] = useState(undefined);

    //loader boolean
    const [loader, setLoader] = useState(true);

    //switches boolean
    const [showMesh, setShowMesh] = useState(false);
    const [bool, setBool] = useState(false);

    const classes = useStyles(loader);

    // instantiate instans of pose
    const myPose = new Pose({
        // locate file dependencies
        locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
        },
    });

    //pose settings
    myPose.setOptions({
        modelComplexity: 0,
        smoothLandmarks: true,
        enableSegmentation: true,
        smoothSegmentation: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
    });

    //waiting for function and gets parameter
    myPose.onResults((results) => {
        //img from server ,temporary solution
        setImg(results.image);
        setPoseLandmarksState(results);
        setLoader(false);
    });

    return (
        <>
            {loader && (
                <div className={classes.loaderContainer}>
                    <CircularProgress className={classes.loader} size={300}/>{" "}
                </div>
            )}
            <div className={classes.main}>
                <Camstream webCamRef={webCamRef} myPose={myPose}/>
                <ServerResultsCanvas
                    canvasRef={canvasRef}
                    poseLandmarks={poseLandmarksState}
                    img={img}
                />
                <div className={classes.switchBtn}>
                    <Switch
                        color={"primary"}
                        value={showMesh}
                        onClick={() => setShowMesh(!showMesh)}
                    />
                    <Typography>ROM</Typography>
                    <Switch color={"error"} value={bool} onClick={() => setBool(!bool)}/>
                    <Typography>REACH TEST</Typography>
                </div>
                {showMesh && (
                    <RangeOfMotion poseLandmarksState={poseLandmarksState}/>
                )}
                {bool && (
                    <ReachTest poseLandmarksState={poseLandmarksState}/>
                )}
            </div>
        </>
    );
}
export default CamView;