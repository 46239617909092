import React from "react";
import { useEffect } from "react";
import * as cam from "@mediapipe/camera_utils";
import Webcam from "react-webcam";
import videoResulution from '../../utils.js'

const Camstream = ({ webCamRef, myPose }) => {
    let camera = null;
    useEffect(() => {
        //have camera
        if (typeof webCamRef.current !== "undefined" && webCamRef.current !== null) {
            camera = new cam.Camera(webCamRef.current.video, {
                onFrame: async () => {
                    // send the image, check if condition is exsist else send null
                    await myPose.send( webCamRef.current ? { image: webCamRef.current.video} : undefined );
                },
                width: videoResulution.videoResulution.Width,
                height: videoResulution.videoResulution.Height,
            });
            camera.start();
        }
    }, []);

    return (
        <div>
            <Webcam className="camera" ref={webCamRef}/>
        </div>
    );
};
export default Camstream;
