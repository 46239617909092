import React from "react";
import * as pose from "@mediapipe/pose";
import { useEffect } from "react";
import videoResulution from '../../utils.js'

//styling
import '../../styles/App.css';

const ServerResultsCanvas = ({ canvasRef, poseLandmarks,img }) => {


	//pose functions that allow me to draw on canvas
	//were added in index.html
	const dConnect = window.drawConnectors;
	const dLandmarks = window.drawLandmarks;

	useEffect(() => {
		if (poseLandmarks !== undefined) {
			// Setting h , w of canvas
			canvasRef.current.width = videoResulution.videoResulution.Width;
			canvasRef.current.height = videoResulution.videoResulution.Height;
			const canvasElement = canvasRef.current;
			const canvasCtx = canvasElement.getContext("2d");
			try {
				canvasCtx.save();
				canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
				canvasCtx.drawImage(img, 0, 0, canvasElement.width, canvasElement.height);
				//draw
				if (poseLandmarks.poseLandmarks) {
					//come to the front
					canvasCtx.globalCompositeOperation = "source-over";
					//skeleton
					//gets canvas, cordinations, body pattern from pose library
					dConnect(canvasCtx, poseLandmarks.poseLandmarks, pose.POSE_CONNECTIONS, {
						color: "#1a1515",
						lineWidth: 2,
					});
					//dots
					dLandmarks(canvasCtx,poseLandmarks.poseLandmarks, {
						color: "#39fc03",
						lineWidth: 0,
					});
					canvasCtx.restore();
				}
			} catch (error) {
				console.log(error)
			}
		}
	}, [canvasRef, dConnect, dLandmarks, img ,poseLandmarks]);

	return (
		<div>
			<canvas className="canvas" ref={canvasRef}></canvas>
		</div>
	);
};

export default ServerResultsCanvas;
