import { useState, useContext, useEffect} from "react";
// components
import {Divider, makeStyles, Typography} from "@material-ui/core";

//context
import ContextSocket from "../context/context-socetio";

const useStyles = makeStyles((theme) => ({
    leftSideMenu: {
        position: "absolute",
        right: "0px",
        width: " auto",
        padding: "10px",
    }
}));

function RangeOfMotion(poseLandmarksState) {
    const classes = useStyles();
    const Socket = useContext(ContextSocket);
    Socket.emit("pose_detect", poseLandmarksState.poseLandmarksState); //todo stop emit when not necessary

    //left angles states
    const [leftKneeAngle, setLeftKneeAngle] = useState(undefined);
    const [leftElbowAngle, setLeftElbowAngle] = useState(undefined);
    const [leftShoulderAbductionAngle, setLeftShoulderAbductionAngle] =
        useState(undefined);
    const [leftShoulderFlexionAngle, setLeftShoulderFlexionAngle] =
        useState(undefined);
    const [leftHipAbductionAngle, setLeftHipAbductionAngle] =
        useState(undefined);

    //right angles states
    const [rightElbowAngle, setRightElbowAngle] = useState(undefined);
    const [rightKneeAngle, setRightKneeAngle] = useState(undefined);
    const [rightShoulderAbductionAngle, setRightShoulderAbductionAngle] =
        useState(undefined);
    const [rightShoulderFlexionAngle, setRightShoulderFlexionAngle] =
        useState(undefined);
    const [rightHipAbductionAngle, setRightHipAbductionAngle] =
        useState(undefined);

    //flexions
    const [rightHipFlexionAngle, setRightHipFlexionAngle] =
        useState(undefined);
    const [leftHipFlexionAngle, setLeftHipFlexionAngle] = useState(undefined);
    const [rightAnkleFlexionAngle, setRightAnkleFlexionAngle] =
        useState(undefined);
    const [leftAnkleFlexionAngle, setLeftAnkleFlexionAngle] =
        useState(undefined);

    //back
    const [BackFlexionAngle, setBackFlexionAngle] = useState(undefined);

    useEffect(() => {
        const Datalistener = (data) => {
            //elbows
            setRightElbowAngle(data.right_elbow_angle);
            setLeftElbowAngle(data.left_elbow_angle);
            //knees
            setRightKneeAngle(data.right_knee_angle);
            setLeftKneeAngle(data.left_knee_angle);
            //shoulder abductions
            setRightShoulderAbductionAngle(data.right_shoulder_abduction_angle);
            setLeftShoulderAbductionAngle(data.left_shoulder_abduction_angle);
            //shoulder flexions
            setRightShoulderFlexionAngle(data.right_shoulder_flexion_angle);
            setLeftShoulderFlexionAngle(data.left_shoulder_flexion_angle);
            //hip abductions
            setRightHipAbductionAngle(data.right_hip_abduction_angle);
            setLeftHipAbductionAngle(data.left_hip_abduction_angle);
            //hip flexions
            setRightHipFlexionAngle(data.right_hip_flexion_angle);
            setLeftHipFlexionAngle(data.left_hip_flexion_angle);
            //ankle flexions
            setRightAnkleFlexionAngle(data.left_ankle_flexion_angle);
            setLeftAnkleFlexionAngle(data.right_ankle_flexion_angle);
            //back flexion
            setBackFlexionAngle(data.back_flexion_angle);
        };
        Socket.on("calculatedAnglesResults", Datalistener);

        return () => {
            Socket.off("calculatedAnglesResults", Datalistener);
        };
    }, []);

    return (
        <>
            <div>
                <div className={classes.leftSideMenu}>
                    <Typography variant="h4"> hip Abduction</Typography>
                    <h4>{` left hip abduction angle: ${leftHipAbductionAngle} `}</h4>
                    <h4>{` right hip abduction angle: ${rightHipAbductionAngle} `}</h4>
                    <Typography variant="h4">hip flexions</Typography>
                    <h4>{` Left hip flexions angle: ${leftHipFlexionAngle} `}</h4>
                    <h4>{` Right hip flexions angle: ${rightHipFlexionAngle} `}</h4>
                    <Divider/>
                    <Typography variant="h4">knee angles</Typography>
                    <h4>{` Left knee angle: ${leftKneeAngle} `}</h4>
                    <h4>{` Right knee angle: ${rightKneeAngle}`}</h4>
                    <Divider/>
                    <Typography variant="h4"> ankle flexions</Typography>
                    <h4>{` left ankle flexions angle: ${leftAnkleFlexionAngle} `}</h4>
                    <h4>{` right ankle flexions angle: ${rightAnkleFlexionAngle} `}</h4>
                </div>
                <div>
                    <Typography variant="h4">shoulder Abductions</Typography>
                    <h4>{` Left shoulderAbduction angle: ${leftShoulderAbductionAngle} `}</h4>
                    <h4>{` Right shoulderAbduction angle: ${rightShoulderAbductionAngle} `}</h4>
                    <Divider/>
                    <Typography variant="h4">shoulder Flexions</Typography>
                    <h4>{` Left shoulder flexion angle: ${leftShoulderFlexionAngle} `}</h4>
                    <h4>{` Right shoulder flexion angle: ${rightShoulderFlexionAngle} `}</h4>
                    <Divider/>
                    <Typography variant="h4">elbow angles</Typography>
                    <h4>{` Left elbow angle: ${leftElbowAngle}`}</h4>
                    <h4>{` Right elbow angle: ${rightElbowAngle}`}</h4>
                    <Divider/>
                    <Typography variant="h4"> back flexion</Typography>
                    <h4>{` back flexion angle: ${BackFlexionAngle} `}</h4>
                </div>
            </div>
        </>
    );
}
export default RangeOfMotion;
